import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { FaFacebookSquare, FaInstagram, FaTwitterSquare } from "react-icons/fa"
// Components

// Styles
import styles from "./scss/footer.module.scss"
const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulFooterSection {
          facebookLink
          twitterLink
          instagramLink
          addressLine1
          addressLine2
          addressLine3
        }
      }
    `}
    render={(data) => (
      <footer className={`section ${styles.footer}`}>
        <div className={styles.social}>
          <ul>
            <li>
              <a
                href={data.contentfulFooterSection.facebookLink}
                aria-label="Link to Brophy Brothers Facebook page"
              >
                <FaFacebookSquare className={styles.facebook} />
              </a>
            </li>
            <li>
              <a
                href={data.contentfulFooterSection.twitterLink}
                aria-label="Link to Brophy Brothers Twitter page"
              >
                <FaTwitterSquare className={styles.twitter} />
              </a>
            </li>
            <li>
              <a
                href={data.contentfulFooterSection.instagramLink}
                aria-label="Link to Brophy Brothers Instagram page"
              >
                <FaInstagram className={styles.instagram} />
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.middle}>
          <div className={styles.address}>
            <a
              href="https://g.page/brophybrothers?share"
              target="_blank noopener noreferrer"
              rel="noopener noreferrer"
              aria-label="Brophy Brothers Google Maps Location"
            >
              <ul>
                <li>{data.contentfulFooterSection.addressLine1}</li>
                <li>{data.contentfulFooterSection.addressLine2}</li>
                <li>{data.contentfulFooterSection.addressLine3}</li>
              </ul>
            </a>
          </div>
          <div className={styles.pages}>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/products" state={{ category: "" }}>
                  Products
                </Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>
          </div>

          <div className={styles.policy}>
            <ul>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/accessibility">Accessibility Statement</Link>
              </li>
              <li>
                <a
                  href="https://www.subscriptionwebsites.ie"
                  target="_blank noopener noreferrer"
                  rel="noopener noreferrer nofollow"
                  aria-label="Subscription Websites Ireland"
                >
                  Built by <b>SWI</b>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.copyright}>
          <span>
            &copy; 1988 - {new Date().getFullYear()} BROPHY BROTHERS WHOLESALE
            FRUIT & VEGETABLES
          </span>
        </div>
      </footer>
    )}
  />
)

export default Footer
