import React from "react"

import Header from "./header"
import Footer from "./footer"
import layoutStyles from "./scss/layout.module.scss"
const Layout = ({ children }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#about"]', {
      speed: 1000,
      speedAsDuration: true,
    })
  }
  return (
    <section className={layoutStyles.container}>
      <section className={layoutStyles.content}>
        <Header />
        <main>{children}</main>
      </section>
      <Footer />
    </section>
  )
}
export default Layout
