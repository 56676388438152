import React, { Component } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { FaFacebookSquare, FaInstagram, FaTwitterSquare } from "react-icons/fa"
// Components

import Image from "../components/image"
// Styles
import styles from "./scss/header.module.scss"
import "./scss/hamburger.css"
class Header extends Component {
  state = {
    toggle: false,
  }

  handleClick = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }))
  }

  closeMenu = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }))
  }

  render() {
    const { toggle } = this.state

    return (
      <header className={styles.header}>
        <div className={styles.topBar}>
          <a
            href="https://g.page/brophybrothers?share"
            target="_blank noopener noreferrer"
            rel="noopener noreferrer"
            className={styles.address}
            aria-label="Brophy Brothers Address"
          >
            {/* {this.props.data.contentfulFooterSection.addressLine2}{" "}
            {this.props.data.contentfulFooterSection.addressLine3} */}
            15 Harrison Avenue Yonkers, NY, 10705
          </a>
          <a
            href="tel:+1914423-5546"
            className={styles.number}
            aria-label="Brophy Brothers Phone Number"
          >
            Call us +1 (914) 423-5546
          </a>
          <span className={styles.social}>
            <a
              href="https://www.facebook.com/brophybrothers/"
              rel="noopener noreferrer"
              target="_blank noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebookSquare className={styles.facebook} />
            </a>
            <a
              href="https://twitter.com/brophybrothers"
              rel="noopener noreferrer"
              target="_blank noopener noreferrer"
              aria-label="Twitter"
            >
              <FaTwitterSquare className={styles.twitter} />
            </a>
            <a
              href="https://www.instagram.com/brophybrothers/"
              rel="noopener noreferrer"
              target="_blank noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram className={styles.instagram} />
            </a>
          </span>
        </div>

        <nav className={styles.nav}>
          <div className={styles.top}>
            <Link to="/">
              <Image image={this.props.data.contentfulHomePageHero.logo} />
            </Link>
            <button
              onClick={this.handleClick}
              className={`hamburger hamburger--collapse ${
                toggle ? "is-active" : ""
              }`}
              type="button"
              aria-expanded={toggle ? "true" : "false"}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
              menu
            </button>
          </div>

          <ul
            tabIndex="-1"
            className={`${styles.ulLinks} ${toggle ? styles.ulOpen : ""}`}
          >
            <li>
              <hr />
            </li>
            <li>
              <Link to="/" onClick={this.closeMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={this.closeMenu}>
                About
              </Link>
            </li>
            <li>
              <Link to="/curbside" onClick={this.closeMenu}>
                Curbside Pickup
              </Link>
            </li>
            <li>
              <Link to="/services" onClick={this.closeMenu}>
                Wholesale Services
              </Link>
            </li>
            <li>
              <Link
                to="/products"
                state={{ category: "" }}
                onClick={this.closeMenu}
              >
                Wholesale Products
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={this.closeMenu}>
                Contact
              </Link>
            </li>
            <li>
              <Link to="/blog" onClick={this.closeMenu}>
                Blog
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    )
  }
}
export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePageHero {
          logo {
            title
            description
            fixed(width: 250, quality: 100) {
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
        contentfulFooterSection {
          facebookLink
          twitterLink
          instagramLink
          addressLine1
          addressLine2
          addressLine3
        }
      }
    `}
    render={(data) => <Header data={data} {...props} />}
  />
)
